import React, { useState, useEffect } from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Box } from '@material-ui/core'
import { Link } from 'gatsby'
import { TextSm, Img } from '@system'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'
import formatLocalizedLink from '@helpers/format-localized-link'

const logos = [
  {
    label: 'Assemble',
    imgPath: '//a.storyblok.com/f/64835/x/2b5d477d2e/assemble-icon.svg',
  },
  {
    label: 'BIM360',
    imgPath: '//a.storyblok.com/f/64835/x/73c8fdc944/bim360-icon.svg',
  },
  {
    label: 'BuildingConnected',
    imgPath:
      '//a.storyblok.com/f/64835/x/93e65bc3c0/buildingconnected-icon.svg',
  },
  {
    label: 'PlanGrid',
    imgPath: '//a.storyblok.com/f/64835/x/3e43912148/plangrid-icon.svg',
  },
]

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    paddingTop: '0.75rem',
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    },
  },
  image: {
    height: '96px',
    flexShrink: 0,

    [theme.breakpoints.up('sm')]: {
      height: '144px',
    },
    '&>div': {
      height: 'inherit',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '1 0 auto',
  },
  companyName: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      paddingTop: '8px',
    },
    display: 'block',
  },
  projectName: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('lg')]: {
      paddingTop: '8px',
    },
    display: 'block',
  },
  logos: {
    paddingRight: '8px',
    '&>img': {
      height: '24px',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: '24px',
      '&>img': {
        height: '32px',
      },
    },
  },
}))

const GalleryModule = (props) => {
  const classes = useStyles()

  const extractFilterOptions = (filterName) => {
    let filterOptions = []
    !!props.blok.galleryItems &&
      props.blok.galleryItems.forEach((item) => {
        if (item.content[filterName]) {
          filterOptions = filterOptions.concat(
            item.content[filterName].filter(
              (option) => filterOptions.indexOf(option) === -1
            )
          )
        }
      })
    return filterOptions
  }

  let filterByParam = ''
  let filterSelectParam = ''

  if (typeof document !== 'undefined') {
    const params = new URL(document.location).search.split('?')
    if (params.length > 1) {
      filterByParam = params[1].split('=')[0]
      filterSelectParam = params[1].split('=')[1]
    }
    if (!!filterByParam && !!filterSelectParam) {
      filterSelectParam = extractFilterOptions(filterByParam).filter(
        (item) =>
          item.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ===
          filterSelectParam.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
      )[0]
    }
  }

  const [filters, setFilters] = useState({
    filterBy: filterByParam || '',
    filterSelect: filterSelectParam || '',
  })

  useEffect(() => {
    setFilters({
      filterBy: filterByParam,
      filterSelect: filterSelectParam,
    })
  }, [filterByParam, filterSelectParam])

  const onChangeFilters = (event, filterName) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      filterBy: filterName,
      filterSelect: event.target.value,
    }))
  }

  const resetFilters = () => {
    setFilters({
      filterBy: '',
      filterSelect: '',
    })
  }

  const compareGalleryItems = (a, b) => {
    return a.content.companyName <= b.content.companyName ? -1 : 1
  }

  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <ContentContainer>
          {!!props.blok.filtersMenu &&
            !!props.blok.filtersMenu.length &&
            props.blok.filtersMenu.map((filtersMenu) => (
              <Box className={classes.filtersContainer} key={filtersMenu._uid}>
                {renderBlok({
                  ...filtersMenu,
                  extractFilterOptions,
                  filters,
                  onChangeFilters,
                  resetFilters,
                })}
              </Box>
            ))}
          <Grid container spacing={2}>
            {!!props.blok.galleryItems &&
              !!props.blok.galleryItems.length &&
              props.blok.galleryItems.sort(compareGalleryItems).map((story) => {
                return (
                  (filters.filterBy === '' ||
                    filters.filterSelect === '' ||
                    (!!story.content[filters.filterBy] &&
                      story.content[filters.filterBy].indexOf(
                        filters.filterSelect
                      ) !== -1)) && (
                    <Grid item xs={6} md={4} lg={3} key={story.id}>
                      <Link
                        to={formatLocalizedLink(story.full_slug)}
                        style={{ textDecoration: 'none' }}
                      >
                        <Box className={classes.card}>
                          <Box className={classes.image}>
                            {!!story.content.image &&
                              !!story.content.image.length && (
                                <Img
                                  maxWidth={300}
                                  src={story.content.image[0].image}
                                />
                              )}
                          </Box>
                          <Box p={1} className={classes.content}>
                            <TextSm className={classes.companyName}>
                              {!!story &&
                                !!story.content &&
                                story.content.companyName}
                            </TextSm>
                            <TextSm className={classes.projectName}>
                              {story.content.projectName}
                            </TextSm>
                            <Box display="flex" pt={2}>
                              {!!logos &&
                                logos.map((logo) => {
                                  return (
                                    !!story.content.productsUsed &&
                                    !!story.content.productsUsed.length &&
                                    story.content.productsUsed.indexOf(
                                      logo.label
                                    ) !== -1 && (
                                      <Box
                                        key={logo.imgPath}
                                        className={classes.logos}
                                      >
                                        <img
                                          src={logo.imgPath}
                                          alt="Autodesk Construction Cloud product logo"
                                        ></img>
                                      </Box>
                                    )
                                  )
                                })}
                            </Box>
                          </Box>
                        </Box>
                      </Link>
                    </Grid>
                  )
                )
              })}
          </Grid>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default GalleryModule
